<template>
	<div>
		<div class="card" v-if="token">
			<div class="card-header">
				{{ $t('setPassword.setPassword') }}
			</div>
			<div class="card-body">
				<form>
					<div class="row">
						<div class="col-sm-6">
							<div class="form-group">
								<label for="password">{{ $t('setPassword.password') }}:</label>
								<PasswordInput
									id="password"
									v-model="password"
									:msgs="msgs"
									class="form-control"
									maxlength="255"
								/>
								<ComponentMessage :msgs="msgs" forComponent="password"/>
							</div>
						</div>

						<div class="col-sm-6">
							<div class="form-group">
								<label for="password2">{{ $t('setPassword.password2') }}:</label>
								<PasswordInput
									id="password2"
									v-model="password2"
									:msgs="msgs"
									class="form-control"
									maxlength="255"
									:password1="password"
								/>
								<ComponentMessage :msgs="msgs" forComponent="password2"/>
							</div>
						</div>
					</div>

					<div class="form-group text-center">
						<button class="btn btn-primary" @click="save" type="button">
							<font-awesome-icon icon="check"/>
							{{ $t('setPassword.save') }}
						</button>
					</div>

				</form>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: 'ForgottenPasswordView',
	data() {
		return {
			emailSent: false,
			email: '',
			password: '',
			password2: '',
			token: null
		};
	},
	props: {
		msgs: Object,
		app: Object
	},
	methods: {
		async save() {
			const ut = await this.$rest.setPassword(this.token, this.password, this.password2)
			console.log({ ut });
			if(ut){
				this.$emit('loginCallback', ut, (userData) => {
					this.username = null;
					this.password = null;
				});
			}
		},
		parseToken() {
			const token = this.$route.params.token
			if(token) {
				this.token = token
			}
		}
	},
	async mounted() {
		if(this.app.loggedInUserData) {
			await this.app.logout(true, false)
		}
		this.parseToken()
	}
}
</script>
